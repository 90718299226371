import React from "react";

import illustrationSmartVariables from './images/SmartVariables.svg';
import illustrationSearch from './images/Search.svg';
import illustrationSearchMobile from './images/SearchMobile.svg';

import ScrollAnimation from 'react-animate-on-scroll';
import { Container, Row, Col } from 'reactstrap';
import MediaQuery from 'react-responsive'

import './App.css';
import "animate.css/animate.min.css";

import Hero from './Hero';
import Copies from './Copies';


function App() {
  return (
    <div>
      <Hero />
      <div className="sectionDark">
        <Copies />
        <Container>
          <hr className="spacerSmall"></hr>
          <Row>
            <Col md={5}>
              <h1 className="title_dark h1">Smart variables for smarter copy</h1>
              <hr className="spacerSmall"></hr>
              <p className="title_dark">Set a custom variable to better suit your product. Your personal UX copywriter complete with templates and examples.
              </p>
            </Col>
            <div className="title dark col-md-7 illustrationSmartVariables">
              <ScrollAnimation animateIn='animate__fadeInUp' delay={250}>
                <img src={illustrationSmartVariables} alt="Illustration showing smart variables"></img>
              </ScrollAnimation>
            </div>
          </Row>
          <hr className="spacerLarge"></hr>
          <Row className="evenRow">
            <div className="title dark col-md-7 illustrationSearch">
              <ScrollAnimation animateIn='animate__fadeInUp'>
                <MediaQuery minWidth={990}>
                  <img src={illustrationSearch} alt="Illustration showing search"></img>
                </MediaQuery>
                <MediaQuery maxWidth={990}>
                  <img src={illustrationSearchMobile} alt="Illustration showing search"></img>
                </MediaQuery>
              </ScrollAnimation>
            </div>
            <Col md={5}>
              <h1 className="title_dark h1">Sign up to unlock more features</h1>
              <hr className="spacerSmall"></hr>
              <a href="https://f386e063.sibforms.com/serve/MUIEAH0ZMMDur3y2l13aVhnjRCZcOqrWJbZiAWEShSzL8dpRhNhntEU73KgvPza_O313Uj-OOXiz7CUklx107DlfhtT7rVySZL6qJyW3GwRbnLmIaL8mBNWbrNcgWqsOZJEcQFkSFei9rLAsek3CvJQmIrtzZu7O9kNOtZVMSp_mPHCHmO315OmEe5YKaWuWjfmCNjTpZ1nsbcBl" rel="noreferrer" className="secondary button">Sign up to get full access</a>
            </Col>
          </Row>
        </Container>
        <hr className="spacerSmall"></hr>
        <div className="sectionDark text-center footer">
          <Container>
            <Row>
              <p>Built by
                <a href="https://twitter.com/peterondesign" target="_blank" rel="noopener noreferrer">@peterondesign</a>
              </p>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
}

export default App;
