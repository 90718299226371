import React from "react";
import { Routes, Route } from "react-router-dom";

import Home from "./Home";
import Dashboard from "./Dashboard";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="dashboard"  element={<Dashboard />}/>
      </Routes>    </div>
  );
}

export default App;
