import React from "react";
import './App.css';
import copybuddylogo from './images/copybuddy.svg';
import IconSearch from './images/IconSearch.svg';

import { Row } from 'reactstrap';
import { Link } from "react-router-dom";

function Dashboard() {
  return (
    <div>
      <div className="mediumGray">
        <Row className="headerCustom mediumPadding largeContainer">
          <div>
            <Link to="/">
              <img src={copybuddylogo} alt="Copy Buddy Logo"></img>
            </Link>
          </div>
          <div>
            <tr className="avatar">PI</tr>
          </div>
        </Row>
        <Row>
          <div className="largeContainer">
            <form>
              <div className="searchBar">
                <div>
                <img src={IconSearch} alt="Search icon"></img>
                <input type="text" className="inputSearch" id="lname" name="lastname" placeholder="Search for any copy"/>
                </div>
                <div className="searchButtonGroup">
                  <a rel="noreferrer" className="secondary button">Modify Variables</a>
                  <a rel="noreferrer" className="primary button">Search</a>
                </div>
              </div>
            </form>
          </div>
        </Row>
        <hr className="spacerShortBottom"></hr>
      </div>
    </div >
  );
}

export default Dashboard;
